import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyCKqsq319y7YpKzo10FHS4uL8v23QnarUU',
  authDomain: 'mtw-isrc-demo.firebaseapp.com',
  databaseURL: 'https://mtw-isrc-demo.firebaseio.com',
  projectId: 'mtw-isrc-demo',
  storageBucket: 'mtw-isrc-demo.appspot.com',
  messagingSenderId: '980905553149',
  appId: '1:980905553149:web:59da8c9af3bc0713e36294',
};
// Initialize Firebase

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

if (process.env.NODE_ENV === 'development') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const db = firebase.firestore();
const functions = firebase.functions();
export { db, firebase, functions };
